import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  
  const logo = (
    <Box
      component="img"
      src="/logo/logo_single.png"
      sx={{ align: 'center', cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  const logoBanner = (
    <Box
      component="img"
      src="/logo/logo_single_1.png"
      sx={{ align: 'center', cursor: 'pointer', ...sx }}
    />
  );

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logoBanner}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
