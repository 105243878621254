import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
// @mui
import { Box, Grid } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';

// ----------------------------------------------------------------------

export default function DefaultLayout() {
  
  const { user } = useAuthContext();

  useEffect(() => {
            
    if(Number(user.isLogin) === 0 && isInDays(user.createdAt, user.participant.programs[0].deadlineNotJoin)){
      window.location.href = "/";
    }

    if(Number(user.isLogin) === 1 && isInDays(user.createdAt, user.participant.programs[0].deadlineJoin)){
      window.location.href = "/";
    }
  
    }, [user]);


  return (
    <Box
      fullWidth
      sx={{
        pb: 4,
        position: 'relative',
        minHeight: '100vh', 
        backgroundImage: `url(${"/assets/images/fondo_azul.jpeg"})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box
            component="img"
            src="/assets/images/logo_blanco2.png"
            sx={{ align: 'center', width: {xs: '80%', md: '50%'}, ml: 'auto', mr: 'auto', my: 5 }}
          />
          <Default>
            <Outlet />
          </Default>
        </Grid>
      </Grid>
  </Box>
  );
}

// ----------------------------------------------------------------------

Default.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

function Default({ children, sx, ...other }) {

  return (
    <Box
      component="main"
    >
      {children}
    </Box>
  );
}


function isInDays(date, days){
  const today = new Date();
  const date90 = new Date(date);
  date90.setDate(date90.getDate() + days);
  if(today > date90){
    return false;
  }
  return true;
}
