import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from '../dashboard/Main';
import Header from '../dashboard/header';
import NavMini from '../dashboard/nav/NavMini';
import NavVertical from '../dashboard/nav/NavVertical';
import { navSupervisor } from '../nav/config-navigation'
import NavHorizontal from '../dashboard/nav/NavHorizontal';

// ----------------------------------------------------------------------

export default function SupervisorLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} navData={navSupervisor} />;

  return (
    <>
      <Header onOpenNav={handleOpen} />
        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
        <NavHorizontal navData={navSupervisor} />

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
