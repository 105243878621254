import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default function RoleBasedGuard({ hasContent, roles, children }) {
  // Logic here to get current user role
  const { user } = useAuthContext();

  const userRoles = user?.roles; // admin;

  if (typeof roles !== 'undefined' && !userRoles.some(userRole => roles.includes(userRole) )) {

    // initial
    if(Number(user.isLogin)){
      return <Navigate to="/programa" />;
    }

    return hasContent ? (
      <Navigate to="/" replace />
    ) : null;
  }

  return <> {children} </>;
}
