import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithRedirect
} from 'firebase/auth';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
// config
import { FIREBASE_API, EMOCIONES_AUDIOS_ACTIVADORES, EMOCIONES_AUDIOS_MEDITACIONES } from '../config-global';
// utils
import axios from '../utils/axios';
//
import { setSession, logoutToken } from './utils';

import api from '../utils/api';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  isLoadingLogin: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const MICROSOFT_PROVIDER = new OAuthProvider('microsoft.com');

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (fAuth) => {
        if (fAuth) {
          try{
            
            let mergedUserRole;

            const rEclas = await axios.post('/authentication', {
              strategy: 'firebase',
              access_token: fAuth.accessToken,
            });

            const user = rEclas.data.user;

            const accessToken = rEclas.data.accessToken;

            mergedUserRole = mergedUserRole ? [...mergedUserRole, ...rEclas.data.user.roles] : rEclas.data.user.roles;
        
            mergedUserRole = mergedUserRole.reduce((result, obj) => {
              if (!result.some(item => item.id === obj.id)) {
                result.push(obj);
              }
              return result;
            }, []);

            if(!user.participant && !user.roles.some(rol => rol.id === 'admin' || rol.id === 'supervisor')){
              // window.location.href = '/not-member';
              // window.location.href = `${APP_STRIPE_PAYMENT_LINK}?prefilled_email=${user.email}`;
              // return;
            }
              
            setSession(accessToken);

            dispatch({
              type: 'INITIAL',
              payload: {
                isAuthenticated: true,
                user: {
                  ...user,
                  photoURL: user.picture,
                  roles: mergedUserRole.map( rol => rol.id),
                  audioSetList: user.audioType === 'activadores' && EMOCIONES_AUDIOS_ACTIVADORES || EMOCIONES_AUDIOS_MEDITACIONES,
                  participantsProgramId: user.participant?.programs[0]?.participants_programs.id || null,
                },
              },
            });

            }catch(error){
              
              if(error.code === 403){
                // window.location.href = `${APP_STRIPE_PAYMENT_LINK}?prefilled_email=${fAuth.email}`;
                // window.location.href = '/not-member';
              }else{
                console.error(error);
              }

            }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });

        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(() => {
    signInWithPopup(AUTH, GOOGLE_PROVIDER);
  }, []);
  const loginWithMicrosoft = useCallback(() => {
    signInWithPopup(AUTH, MICROSOFT_PROVIDER);
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, name) => {
    await createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);

      await api.postRegister({
        id: res.user?.uid,
        email,
        password,
        name,
        firebaseId: email
      });

      setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${name}`,
      });

      window.location.href = '/';
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    logoutToken();
    signOut(AUTH);
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      login,
      loginWithGoogle,
      loginWithMicrosoft,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      loginWithMicrosoft,
      loginWithGoogle,
      register,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
