import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
// @mui
import { Box, Grid } from '@mui/material';

// ----------------------------------------------------------------------

export default function FirstLoginLayout() {
  return (
    <Box
      fullWidth
      sx={{
        pb: 4,
        position: 'relative',
        minHeight: '100vh', 
        backgroundImage: `url(${"/assets/images/fondo_azul.jpeg"})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box
            component="img"
            src="/assets/images/logo_blanco2.png"
            sx={{ align: 'center', width: {xs: '80%', md: '50%'}, ml: 'auto', mr: 'auto', my: 5 }}
          />
          <MainFirstlogin >
            <Outlet />
          </MainFirstlogin>
        </Grid>
      </Grid>
  </Box>
  );
}

// ----------------------------------------------------------------------

MainFirstlogin.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

function MainFirstlogin({ children, sx, ...other }) {

  return (
    <Box
      component="main"
    >
      {children}
    </Box>
  );
}