import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from '../dashboard/Main';
import Header from '../dashboard/header';
import NavMini from '../dashboard/nav/NavMini';
import NavVertical from '../dashboard/nav/NavVertical';
import NavHorizontal from '../dashboard/nav/NavHorizontal';
import { navParticipants, navEclass } from '../nav/config-navigation'

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { user } = useAuthContext();

  const { moduleId } = useParams();

  const userRoles = user?.roles; // admin;
  
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
            
    if(Number(user.isLogin) === 0 && user.participant && !isInDays(user.createdAt, user.participant.programs[0].deadlineNotJoin)){
      window.location.href = "/out-of-date";
    }

    if(Number(user.isLogin) === 1 && user.participant && !isInDays(user.createdAt, user.participant.programs[0].deadlineJoin)){
      window.location.href = "/out-of-date";
    }
  
  }, [user]);

  return (
    <>
      <Header onOpenNav={handleOpen} />
        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >

      {
        user.participant && <NavHorizontal navData={navParticipants(moduleId)} />
      }

      <Main>
        <Outlet />
      </Main>
      </Box>
    </>
  );
}


function isInDays(date, days){
  const today = new Date();
  const date90 = new Date(date);
  date90.setDate(date90.getDate() + days);
  if(today > date90){
    return false;
  }
  return true;
}
