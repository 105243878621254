// routes
import { PATH_DASHBOARD, PATH_POSTULANTE, PATH_STUDENTS, PATH_SUPERVISOR, PATH_EVALUATOR, PATH_PARTICIPANTS } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};
export const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'administración',
    items: [
      // { title: 'general', path: '#', icon: ICONS.dashboard, disabled: true },
      { title: 'programas', path: PATH_DASHBOARD.programs.general, icon: <Iconify icon="icon-park-outline:file-success"/> },
      // { title: 'usuarios', path: PATH_DASHBOARD.user.list, icon: ICONS.banking },
      // { title: 'configuración', path: '#', icon: ICONS.booking, disabled: true }
    ],
  }
];

export const navEvaluador = [
  // EVALUADORES
  // ----------------------------------------------------------------------
  {
    subheader: 'evaluación',
    items: [
      { title: 'evaluar', path: PATH_EVALUATOR.evaluation.calls, icon: ICONS.dashboard, disabled: true },
    ],
  }
];

export const navParticipants = (isModule) => [
  // PARTICIPANTES
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Inicio', path: PATH_PARTICIPANTS.root, icon: <Iconify icon="eva:home-outline" /> },
      { title: 'Configuración', path: PATH_PARTICIPANTS.configuration, icon: <Iconify icon="eva:settings-2-outline" /> }
    ].filter(i => isModule && i.title === 'Inicio' || !isModule && (i.title === 'Configuración' || i.title === 'Inicio')),
  }
];

// ----------------------------------------------------------------------
// ECLASS
// ----------------------------------------------------------------------

// ADMIN HORIZONTAL BAR
// ----------------------------------------------------------------------
export const navAdminEClass = (programId) => [
  {
    items: [
      { title: 'reporteria', path: PATH_DASHBOARD.programs.dashboard(programId), icon: ICONS.dashboard },
      { title: 'agregar participante', path: PATH_DASHBOARD.programs.newParticipant(programId), icon: <Iconify icon="eva:person-fill"/> },
      // { title: 'módulos', path: PATH_DASHBOARD.programs.modules(programId), icon: <Iconify icon="icon-park-outline:file-success"/> },
      // { title: 'coaches', path: PATH_DASHBOARD.programs.evaluations(programId), icon: <Iconify icon="carbon:task-approved"/> },
      // { title: 'configuración', path: PATH_DASHBOARD.programs.config(programId), icon: <Iconify icon="eva:settings-2-outline"/> }
    ],
  }
];

// SUPERVISOR
// ----------------------------------------------------------------------
export const navSupervisor = [
  {
    subheader: '',
    items: [
      { title: 'reportería', path: PATH_SUPERVISOR.root, icon: <Iconify icon="carbon:task-approved"/> }
    ]
  }
];


// programa
// ----------------------------------------------------------------------
export const navEclass = (roles) =>  [
  {
    subheader: '',
    items: [
      { title: 'programa', path: PATH_STUDENTS.root, icon: <Iconify icon="mdi:teacher"/> }
    ],
  }
];
