import PropTypes from 'prop-types';
import { Video } from '@mui/material';
import { useState } from 'react';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
// import './Sample.css';
// //
// import Scrollbar from '../../scrollbar';
// //
import Image from '../../image';

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

// const options = {
//   cMapUrl: 'cmaps/',
//   cMapPacked: true,
//   standardFontDataUrl: 'standard_fonts/',
// };

export default function SingleFilePreview({ file }) {
  // const [numPages, setNumPages] = useState(null);
  
  // const [pageNumber, setPageNumber] = useState(1);

  // const onDocumentLoadSuccess = ({numPages}) => {
  //   setNumPages(numPages);
  // }

  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
