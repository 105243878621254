// @mui
import { Divider, IconButton, Stack, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { loginWithGoogle, loginWithMicrosoft, user } = useAuthContext();
  
  const handleGoogleLogin = async () => {
    try {
      if (loginWithGoogle) {
        loginWithGoogle();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      if (loginWithMicrosoft) {
        loginWithMicrosoft();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        O ingresar con
      </Divider>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <LoadingButton
          variant="outlined"
          color="inherit"
          size="large"
          onClick={handleGoogleLogin}
          startIcon={<Iconify icon="logos:google-icon" />}
          loading={user?.isLoadingLogin || false}
        >
          Google
        </LoadingButton>

        <LoadingButton
          variant="outlined"
          color="inherit"
          size="large"
          onClick={handleMicrosoftLogin}
          startIcon={<Iconify icon="logos:microsoft-icon" />}
          loading={user?.isLoadingLogin || false}
        >
          Microsoft
        </LoadingButton>
    
      </Stack>
    </div>
  );
}
