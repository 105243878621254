import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import FirstLoginLayout from '../layouts/firstLogin';
import PostulanteLayout from '../layouts/postulante';
import SupervisorLayout from '../layouts/supervisor'
import RouteLayouts from '../layouts/RouteLayouts';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import DefaultLayouts from '../layouts/default';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  // Dashboard: (programa)
  GeneralPage,
  EClassNewPage,
  AdminDashboardPage,
  ParticipantsListPage,
  NewParticipantPage,
  EClassStudentsEditPage,
  EClassModulesPage,
  EClassModulesNewPage,
  EClassModulesEditPage,
  EClassEvaluationsPage,
  // EVALUATOR
  SupervisorDashboardPage,
  EvaluatorEclassModulesPage,
  StudentModuleEvaluatePage,
  // CONFIGURATION
  ConfigurationPage,
  // STUDENT
  StudentsEclassPage,
  StudentsEclassModulesPage,
  StudentsModulePage,
  //
  Page500,
  Page403,
  Page404,
  // FirstLoginPage
  FirstLoginPage,
  // erros
  NotMemberPage,
  OutOfDate,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {

  const routes = [
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },
    // root
    {
      path: '/',
      element: (
        <AuthGuard>
            <RouteLayouts />
        </AuthGuard>
      )
    },
    {
      path: 'out-of-date',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['participants']}>
            <DefaultLayouts />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <OutOfDate />, index: true },
      ],
    },
    {
      path: 'not-member',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['user']}>
            <PostulanteLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <NotMemberPage />, index: true },
      ],
    },
    {
      path: 'init',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['participants']}>
            <FirstLoginLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <FirstLoginPage />, index: true },
      ],
    },
    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['admin']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/programas" replace />, index: true },
        { path: 'programas', element: <GeneralPage /> },
        { path: 'nueva', element: <EClassNewPage /> },
        { path: 'programa/:programId/reporteria', element: <AdminDashboardPage /> },
        // participants
        { path: 'programa/:programId/participantes', element: <ParticipantsListPage /> },
        { path: 'programa/:programId/participantes/nuevo', element: <NewParticipantPage /> },
        { path: 'programa/:programId/participantes/:studentId', element: <EClassStudentsEditPage /> },
        // modules
        { path: 'programa/:programId/modulos', element: <EClassModulesPage /> },
        { path: 'programa/:programId/modulos/nuevo', element: <EClassModulesNewPage /> },
        { path: 'programa/:programId/modulos/:moduleId/edit', element: <EClassModulesEditPage /> },
        { path: 'programa/:programId/evaluaciones', element: <EClassEvaluationsPage /> },
      ],
    },
    // Configuration
    {
      path: 'configuration',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['participants']}>
            <PostulanteLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <ConfigurationPage />, index: true },
      ],
    },
    // Estudiate
    {
      path: 'programa',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['participants']}>
            <PostulanteLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <StudentsEclassPage />, index: true },
        { path: ':courseId/modulos', element: <StudentsEclassModulesPage /> },
        { path: ':courseId/modulos/:moduleId', element: <StudentsModulePage /> },
      ],
    },
    // Evaluador / Mentor
    {
      path: 'supervisor',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['supervisor']}>
            <SupervisorLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <SupervisorDashboardPage />, index: true },
        { path: ':courseId/modulos', element: <EvaluatorEclassModulesPage /> },
        { path: ':courseId/modulos/:studentsClassApplicationId/evaluar', element: <StudentModuleEvaluatePage /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <CompactLayout />
        </AuthGuard>
      ),
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]

  return useRoutes( routes );
}
