import axios from 'axios';
// config
import { HOST_API_KEY, APP_ORGANIZATION_ID } from '../config-global';

// ----------------------------------------------------------------------
// API Postulaciones

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.request.use(config => {
  config.headers['X-App-Organization-Id'] = APP_ORGANIZATION_ID;
  return config;
});

export default axiosInstance;
