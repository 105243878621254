import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function PageNotFoundIllustration({ ...other }) {

  return (
    <Box
        component="img"
        src="/assets/images/imagen_error.png"
        sx={{ align: 'center' }}
      />
  );
}

export default memo(PageNotFoundIllustration);
