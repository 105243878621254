import { Navigate } from 'react-router-dom';
// 
import { useAuthContext } from '../auth/useAuthContext';

// ----------------------------------------------------------------------

export default function RouteLayouts() {
    const { user, isAuthenticated } = useAuthContext();

    if (isAuthenticated) {
        if(user.roles.includes('admin')){
            return <Navigate to="/dashboard" />;
        }
    
        if(user.roles.includes('evaluator')){
            return <Navigate to="/evaluaciones" />;
        }

        if(user.roles.includes('supervisor')){
            return <Navigate to="/supervisor" />;
        }
    
        if(user.roles.includes('participants')){
            if(Number(user.isLogin)){
                return (<Navigate to="/programa" />);
            }
            return (<Navigate to="/init" />);
        }

        return <Navigate to="/not-member" />;
    }

}
