// routes
import { PATH_DASHBOARD, PATH_POSTULANTE, PATH_EVALUATOR } from './routes/paths';
// components
import Iconify from './components/iconify';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const HOST_API_KEY_ECLASS = process.env.REACT_APP_HOST_API_ECLASS_KEY || '';

export const APP_ORGANIZATION_ID = process.env.REACT_APP_ORGANIZATION_ID || '';

export const APP_ORGANIZATION_LOGO = process.env.REACT_APP_ORGANIZATION_LOGO || '';

export const APP_ORGANIZATION_TITLE = process.env.REACT_APP_ORGANIZATION_TITLE || '';

export const APP_STRIPE_PAYMENT_LINK = process.env.REACT_APP_STRIPE_PAYMENT_LINK || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.ecommerce; // as '/dashboard/app'
export const PATH_AFTER_LOGIN_POSTULACIONES = PATH_POSTULANTE.calls; // as '/convocatorias'
export const PATH_AFTER_LOGIN_EVALUATOR = PATH_EVALUATOR.evaluation.calls;
export const PATH_AFTER_LOGIN_ECLASS = PATH_DASHBOARD.programs.general;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// MAXSIZE
// ----------------------------------------------------------------------
export const MAXSIZE = {
  F_VIDEO: 1024 * 1024 * 55,
  F_FILE: 1024 * 1024 * 55,
};

export const ACCEPT_FILES_CALLS = {
  'image/*': [],
  'application/pdf': [],
  'video/*': [],
}

// RESPONSE OPTIONS CALLS FORMS
// ----------------------------------------------------------------------

export const RESPONSE_OPTIONS_CALLS = [
  { id: 1, name: 'respuesta corta', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { id: 2, name: 'párrafo', points: 0, icon: <Iconify icon="fluent:text-description-20-filled" sx={{mx: 1}}/>, type: 'text'  },
  { id: 3, name: 'fecha', points: 0, icon: <Iconify icon="material-symbols:calendar-month-rounded" sx={{mx: 1}}/>, type: 'datepicker'  },
  { id: 4, name: 'opción múltiple', points: 0, icon: <Iconify icon="vaadin:options" sx={{mx: 1}}/>, type: 'radiogroup'  },
  { id: 5, name: 'casillas de verificación', points: 0, icon: <Iconify icon="ri:checkbox-line" sx={{mx: 1}}/>, type: 'multicheckbox' },
  { id: 6, name: 'carga archivos', points: 0, icon: <Iconify icon="ic:outline-cloud-upload" sx={{mx: 1}}/>, type: 'singleUpload' },
  { id: 7, name: 'lista desplegable', points: 0, icon: <Iconify icon="icons8:chevron-down-round" sx={{mx: 1}}/>, type: 'select' },
  { id: 8, name: 'dirección', points: 0, icon: <Iconify icon="material-symbols:map" sx={{mx: 1}}/>, type: 'text'  },
  { id: 9, name: 'bases de la convocatoria', points: 0, icon: <Iconify icon="iconoir:privacy-policy" sx={{mx: 1}}/>, type: 'checkbox'  },
  { id: 10, name: 'RUT', points: 0, icon: <Iconify icon="mdi:id-card-outline" sx={{mx: 1}}/>, type: 'regex', regex: /\d{3,8}-[\d|kK]{1}/ },
  { id: 11, name: 'número', points: 0, icon: <Iconify icon="octicon:number-24" sx={{mx: 1}}/>, type: 'number' },
  // { id: 7, name: 'tabla', points: 0, icon: <Iconify icon="material-symbols:table-chart-outline" sx={{mx: 1}}/>  },
];

// RESPONSE OPTIONS MODULE FORMS
// ----------------------------------------------------------------------

export const RESPONSE_OPTIONS = [
  { dash: true, value: "Preguntas"},
  { id: 1, name: 'respuesta corta', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { id: 2, name: 'párrafo', points: 0, icon: <Iconify icon="fluent:text-description-20-filled" sx={{mx: 1}}/>, type: 'text'  },
  { id: 3, name: 'fecha', points: 0, icon: <Iconify icon="material-symbols:calendar-month-rounded" sx={{mx: 1}}/>, type: 'datepicker'  },
  { id: 4, name: 'opción múltiple', points: 0, icon: <Iconify icon="vaadin:options" sx={{mx: 1}}/>, type: 'radiogroup'  },
  { id: 5, name: 'casillas de verificación', points: 0, icon: <Iconify icon="ri:checkbox-line" sx={{mx: 1}}/>, type: 'multicheckbox' },
  { id: 6, name: 'carga archivos', points: 0, icon: <Iconify icon="ic:outline-cloud-upload" sx={{mx: 1}}/>, type: 'singleUpload' },
  { id: 7, name: 'lista desplegable', points: 0, icon: <Iconify icon="icons8:chevron-down-round" sx={{mx: 1}}/>, type: 'select' },
  { id: 10, name: 'RUT', points: 0, icon: <Iconify icon="mdi:id-card-outline" sx={{mx: 1}}/>, type: 'regex', regex: /\d{3,8}-[\d|kK]{1}/ },
  { id: 16, name: 'audio', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { id: 17, name: 'tabla de hábito', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { id: 18, name: 'tabla emociones', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { id: 19, name: 'plan de acción', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { id: 20, name: 'árbol personas', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { id: 21, name: 'movilizadores', points: 0, icon: <Iconify icon="material-symbols:short-text" sx={{mx: 1}}/>, type: 'text' },
  { dash: true, value: "Material"},
  { id: 14, name: 'contenido', points: 0, icon: <Iconify icon="eva:credit-card-outline" sx={{mx: 1}}/>, type: 'editor' },
  { id: 1401, name: 'contenido bienvenida', points: 0, icon: <Iconify icon="eva:credit-card-outline" sx={{mx: 1}}/>, type: 'editor' },
  { id: 1402, name: 'contenido bienvenida warning', points: 0, icon: <Iconify icon="eva:credit-card-outline" sx={{mx: 1}}/>, type: 'editor' },
  { id: 15, name: 'descargar archivo', points: 0, icon: <Iconify icon="eva:file-text-outline" sx={{mx: 1}}/>, type: 'multiUpload'  },
];

export const ACCEPT_FILES_ECLASS = {
  helperText: '',
  accept: {
    'image/*': [],
    'application/pdf': [],
    "application/msword": [], // .doc, .dot 
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // .docx
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template": [], // .dotx
    "application/vnd.ms-word.document.macroEnabled.12": [], // .docm
    "application/vnd.ms-word.template.macroEnabled.12": [], // .dotm
    "application/vnd.ms-excel": [], // .xls, .xlt, .xla 
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // .xlsx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [], // .xltx
    "application/vnd.ms-excel.sheet.macroEnabled.12": [], // .xlsm
    "application/vnd.ms-excel.template.macroEnabled.12": [], // .xltm
    "application/vnd.ms-excel.addin.macroEnabled.12": [], // .xlam
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [], // .xlsb
    "application/vnd.ms-powerpoint": [], // .ppt, .pot, .pps, .ppa 
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // .pptx
    "application/vnd.openxmlformats-officedocument.presentationml.template": [], // .potx
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow": [], // .ppsx
    "application/vnd.ms-powerpoint.addin.macroEnabled.12": [], // .ppam
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12": [], // .pptm
    "application/vnd.ms-powerpoint.template.macroEnabled.12": [], // .potm
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12": [], // .ppsm
    "application/vnd.ms-access": [], // .mdb 
    'video/*': [],
  }
}

export const AUDIOS_ACTIVADORES = {
  '#1A': 'https://storage2wtc01.blob.core.windows.net/audios/%231A.mp3',
  '#2A-E1': 'https://storage2wtc01.blob.core.windows.net/audios/%232AE1.mp3',
  '#2A-E2': 'https://storage2wtc01.blob.core.windows.net/audios/%232AE2.mp3',
  '#2A-E3': 'https://storage2wtc01.blob.core.windows.net/audios/%232AE3.mp3',
  '#2A-E4': 'https://storage2wtc01.blob.core.windows.net/audios/%232AE4.mp3',
  '#2A-E5': 'https://storage2wtc01.blob.core.windows.net/audios/%232AE5.mp3',
  '#2A-E6': 'https://storage2wtc01.blob.core.windows.net/audios/%232AE6.mp3',
  '#2A-E7': 'https://storage2wtc01.blob.core.windows.net/audios/%232AE7.mp3',
  '#3A': 'https://storage2wtc01.blob.core.windows.net/audios/%233A.mp3',
  '#4A': 'https://storage2wtc01.blob.core.windows.net/audios/%234A.mp3',
  '#5A': 'https://storage2wtc01.blob.core.windows.net/audios/%235A.mp3',
  '#6A': 'https://storage2wtc01.blob.core.windows.net/audios/%236A.mp3',
}

export const AUDIOS_MEDITACIONES = {
  '#1M': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%231M.mp3',
  '#2M-E1': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%232ME1.mp3',
  '#2M-E2': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%232ME2.mp3',
  '#2M-E3': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%232ME3.mp3',
  '#2M-E4': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%232ME4.mp3',
  '#2M-E5': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%232ME5.mp3',
  '#2M-E6': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%232ME6.mp3',
  '#2M-E7': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%232ME7.mp3',
  '#3M': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%233M.mp3',
  '#4M': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%234M.mp3',
  '#5M': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%235M.mp3',
  '#6M': 'https://storage2wtc01.blob.core.windows.net/audios/Meditacion%20%236M.mp3',
}

export const EMOCIONES_AUDIOS_ACTIVADORES = {
  preocupacion: '#2A-E1',
  ansiedad: '#2A-E1',
  miedo: '#2A-E1',
  frustracion: '#2A-E2',
  resentimiento: '#2A-E2',
  enojo: '#2A-E2',
  verguenza: '#2A-E3',
  rechazo: '#2A-E3',
  culpa: '#2A-E4',
  desanimo: '#2A-E5',
  desesperanza: '#2A-E5',
  tristeza: '#2A-E5',
  felicidad: '#2A-E6',
  alegria: '#2A-E6',
  confianza: '#2A-E7',
  tranquilidad: '#2A-E7',
}

export const EMOCIONES_AUDIOS_MEDITACIONES = {
  preocupacion: '#2M-E1',
  ansiedad: '#2M-E1',
  miedo: '#2M-E1',
  frustracion: '#2M-E2',
  resentimiento: '#2M-E2',
  enojo: '#2M-E2',
  verguenza: '#2M-E3',
  rechazo: '#2M-E3',
  culpa: '#2M-E4',
  desanimo: '#2M-E5',
  desesperanza: '#2M-E5',
  tristeza: '#2M-E5',
  felicidad: '#2M-E6',
  alegria: '#2M-E6',
  confianza: '#2M-E7',
  tranquilidad: '#2M-E7',
}