import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Box,Typography, Link  } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  
  return (
    <LoginLayout>
      <Stack sx={{ mb: 5}} 
        alignItems="center"
        justifyContent="center">

        <Box
          component="img"
          src="/logo/logo_color1.png"
          sx={{ align: 'center', maxWidth: 180 }}
        />
      </Stack>
      
      <Stack direction="row" spacing={0.5} sx={{mb: 3}} >
        <Typography variant="body2">¿Nuevo usuario?</Typography>

        <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
          Crear cuenta
        </Link>
      </Stack>

      <AuthLoginForm />
      <AuthWithSocial />
    </LoginLayout>
  );
}
