// utils
import axios from './axios';

export default {
    postConfigInitial: async (data) => (await axios.post(`/users-configs`, {...data, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})).data,

    postGoal: async (data) => (await axios.post(`/goals`, data)).data,

    postStatement: async (data) => (await axios.post(`/statements`, data)).data,
    
    postHydrationHabit: async (data) => (await axios.post(`/hydration-habits`, data)).data,

    postWorkoutsHabit: async (data) => (await axios.post(`/workouts-habits`, data)).data,

    postSleepingHabit: async (data) => (await axios.post(`/sleeping-habits`, data)).data,

    postFeedingHabit: async (data) => (await axios.post(`/feeding-habits`, data)).data,

    postDistractors: async (data) => (await axios.post(`/distractors`, data)).data,

    postCollages: async (data) => (await axios.post(`/collages`, data)).data,
    
    postActionsPlans: async (data) => (await axios.post(`/actions-plans`, data)).data,

    postParticipantsModulesAppply: async (data) => (await axios.post('/participants-modules-apply', data)).data,

    postRegister: async (data) => axios.post('/users', data),

    postEmotion: async (data) => (await axios.post(`/emotions`, data)).data,

    postAddParticipant: async (data) => (await axios.post(`/participants-programs`, data)).data,

    postEnvironment: async (data) => (await axios.post(`/personal-labor-environments`, data)).data,

    // ------------------------------

    findGoals: async (params = '') => (await axios.get(`/goals${params}`)).data.data?.[0] || {},

    findStatements: async (params = '') => (await axios.get(`/statements${params}`)).data.data?.[0] || {},

    findHydrationHabits: async (params = '') => (await axios.get(`/hydration-habits${params}`)).data.data,

    findSleepingHabits: async (params = '') => (await axios.get(`/sleeping-habits${params}`)).data.data,

    findFeedingHabit: async (params = '') => (await axios.get(`/feeding-habits${params}`)).data.data,
    
    findWorkoutsHabit: async (params = '') => (await axios.get(`/workouts-habits${params}`)).data.data,

    findCollages: async (userId, programId) => (await axios.get(`/collages${ (userId && programId) ? `?userId=${userId}&programId=${programId}` : '' }`)).data.data,

    findActionsPlans: async (userId, programId) => (await axios.get(`/actions-plans${ (userId && programId) ? `?userId=${userId}&programId=${programId}` : '' }`)).data.data,

    findDistractors: async (userId, programId) => (await axios.get(`/distractors${ (userId && programId) ? `?userId=${userId}&programId=${programId}` : '' }`)).data.data,

    findModulesPending: async ( participansProgramId ) => (await axios.get(`/participants-modules-applications?participantsProgramId=${participansProgramId}&statusId=4&statusId=3`)).data.data,

    findParticipantsModulesAppply: async (courseId, moduleId) => (await axios.get(`/participants-modules-apply?programId=${courseId}&moduleId=${moduleId}`)).data,

    findParticipantsModulesApplications: async (participantsProgramId) => (await axios.get(`/participants-modules-applications?participantsProgramId=${participantsProgramId}`)).data,

    findModules: async ( programId ) => (await axios.get(`/modules?programId=${programId}`)).data,

    findSchedule: async () => (await axios.get(`/users-configs`)).data,

    findProgramsStats: async (programId) => (await axios.get(`/programs-stats?programId=${programId}`)).data,

    findPrograms: async () => (await axios.get(`/programs`)).data,

    findEmotions: async (params = '') => (await axios.get(`/emotions${params}`)).data,

    // ------------------------------

    patchNotes: async (id, notes) => (await axios.patch(`/actions/${id}`, {notes})).data,

    patchProgress: async (id, progress) => (await axios.patch(`/actions/${id}`, {progress})).data,

    patchAction: async (id, data) => (await axios.patch(`/actions/${id}`, data)).data,

    patchParticipantsModulesApplications: async (id, data) => (await axios.patch(`/participants-modules-applications/${id}`, data)).data,

    patchSchedule: async (id, data) => (await axios.patch(`/users-configs/${id}`, data)).data,

    // ------------------------------

    putSleepingHabit: async (id, data) => (await axios.put(`/sleeping-habits/${id}`, data)).data,
    
    putHydrationHabit: async (id, data) => (await axios.put(`/hydration-habits/${id}`, data)).data,
    
    putFeedingHabit: async (id, data) => (await axios.put(`/feeding-habits/${id}`, data)).data,

    putWorkoutsHabit: async (id, data) => (await axios.put(`/workouts-habits/${id}`, data)).data,

    // ------------------------------

    getPrograms: async (programId) => (await axios.get(`/programs/${programId}`)).data,

    // ------------------------------

    downloadGeneralData: async (programId) => (await axios.get(`/download-general-data?programId=${programId}`)).data,
}