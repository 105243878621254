import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));

// ADMIN: E-CLASS (programa)
export const GeneralPage = Loadable(lazy(() => import('../pages/dashboard/GeneralPage')));
export const AdminDashboardPage = Loadable(lazy(() => import('../pages/dashboard/AdminDashboardPage')));
export const ParticipantsListPage = Loadable(lazy(() => import('../pages/dashboard/ParticipantsListPage')));
export const NewParticipantPage = Loadable(lazy(() => import('../pages/dashboard/NewParticipantPage')));
export const EClassStudentsEditPage = Loadable(lazy(() => import('../pages/dashboard/EClassStudentsEditPage')));
export const EClassModulesPage = Loadable(lazy(() => import('../pages/dashboard/EClassModulesPage')));
export const EClassModulesNewPage = Loadable(lazy(() => import('../pages/dashboard/EClassModulesNewPage')));
export const EClassModulesEditPage = Loadable(lazy(() => import('../pages/dashboard/EClassModulesEditPage')));
export const EClassEvaluationsPage = Loadable(lazy(() => import('../pages/dashboard/EClassEvaluationsPage')));
export const EClassNewPage = Loadable(lazy(() => import('../pages/dashboard/EClassNewPage')));

// ECLASS: STUDENTS
export const StudentsEclassPage = Loadable(lazy(() => import('../pages/student/StudentsEclassPage')));
export const StudentsEclassModulesPage = Loadable(lazy(() => import('../pages/student/StudentsEclassModulesPage')));
export const StudentsModulePage = Loadable(lazy(() => import('../pages/student/StudentsModulePage')));

// ECLASS: EVALUATOR
export const SupervisorDashboardPage = Loadable(lazy(() => import('../pages/supervisor/SupervisorDashboardPage')));
export const EvaluatorEclassModulesPage = Loadable(lazy(() => import('../pages/supervisor/EvaluatorEclassModulesPage')));
export const StudentModuleEvaluatePage = Loadable(lazy(() => import('../pages/supervisor/StudentModuleEvaluatePage')));

// CONFIGURATION
export const ConfigurationPage = Loadable(lazy(() => import('../pages/configuration/ConfigurationPage')));

// ----------------------------------------------------------------------

export const FirstLoginPage = Loadable(lazy(() => import('../pages/firstLogin/FirstLoginPage')));

export const NotMemberPage = Loadable(lazy(() => import('../pages/errors/NotMemberPage')));

export const OutOfDate = Loadable(lazy(() => import('../pages/errors/OutOfDate')));

// ----------------------------------------------------------------------

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
